import { useTranslations } from '@vocab/react';
import {
  Box,
  CheckboxStandalone,
  Columns,
  Column,
  Text,
  IconCritical,
  TextLink,
} from 'braid-design-system';
import React, { useEffect } from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import { useAnalyticsContext } from 'src/modules/tracking/analytics/AnalyticsContext';
import { brandName } from 'src/utils/brandName';
import getClientHrefLink from 'src/utils/getClientHrefLink';

import translations from './.vocab';

interface Props {
  privacyAcceptedController: any;
  privacyChkBoxText:
    | 'registration__privacy_consent__checkbox'
    | 'registration__privacy_consent_thai__checkbox';
  isMobileClient: boolean;
}

const ConsentCheckbox = ({
  privacyAcceptedController,
  privacyChkBoxText,
  isMobileClient,
}: Props) => {
  const { t } = useTranslations(translations);
  const context = useAppContext();
  const { analytics } = useAnalyticsContext();

  const privacyLink = getClientHrefLink(context, isMobileClient, '/privacy');

  useEffect(() => {
    if (privacyAcceptedController.errorMessage) {
      analytics.trackLink({
        eventName: 'alert_viewed',
        currentPage: 'social-create-account-confirmation',
        alertShownType: 'notice',
        alertShownTone: 'critical',
        alertShownPosition: 'content',
        alertShownTitle: 'consent check box unselected',
        alertShownMessage: privacyAcceptedController.errorMessage,
      });
    }
  }, [analytics, privacyAcceptedController.errorMessage]);

  return (
    <Box>
      <Columns space="small">
        <Column width="content">
          <CheckboxStandalone
            aria-labelledby="agreement"
            id="privacy-chkbox"
            tone={
              Boolean(privacyAcceptedController.valid) ? 'neutral' : 'critical'
            }
            required
            checked={privacyAcceptedController.value}
            onChange={(e) => {
              privacyAcceptedController.onChange(e);
              privacyAcceptedController.onBlur(e);
            }}
          />
        </Column>
        <Column>
          <Box component="label" htmlFor="privacy-chkbox" cursor="pointer">
            <Text size="small" id="agreement">
              {t(privacyChkBoxText, {
                brand: brandName(
                  context.hostConfig.clientConfig.brand || 'seek',
                ),
                PrivacyPolicyLink: (v: any) => (
                  <TextLink href={privacyLink} target="_blank" weight="weak">
                    {v}
                  </TextLink>
                ),
              })}
            </Text>
          </Box>
        </Column>
      </Columns>
      {!Boolean(privacyAcceptedController.valid) && (
        <Box paddingTop={['medium']}>
          <Text size="small" tone="critical">
            <IconCritical /> &nbsp;
            {privacyAcceptedController.errorMessage}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default ConsentCheckbox;
