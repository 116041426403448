import {
  staging_seek_co_nz,
  staging_seek_com_au,
  hk_staging_jobsdb_com,
  th_staging_jobsdb_com,
  my_staging_jobstreet_com,
  sg_staging_jobstreet_com,
  id_staging_jobstreet_com,
  ph_staging_jobstreet_com,
} from '@seek/online-identity';

import type { RenderConfig } from '../../types';

import dev from './development';

export default {
  hostConfigs: {
    ...dev.hostConfigs,
    [staging_seek_co_nz.clientDomain]: {
      clientConfig: staging_seek_co_nz,
      environment: {
        SITE: 'STAGING_SEEK_NZ',
        COUNTRY: 'New Zealand',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    [staging_seek_com_au.clientDomain]: {
      clientConfig: staging_seek_com_au,
      environment: {
        SITE: 'STAGING_SEEK_AU',
        COUNTRY: 'Australia',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.dev-engineering.cloud.seek.com.au/a.png',
      },
    },
    [th_staging_jobsdb_com.clientDomain]: {
      clientConfig: th_staging_jobsdb_com,
      privacyChkBoxText: 'registration__privacy_consent_thai__checkbox',
      environment: {
        SITE: 'JOBSDB_TH',
        COUNTRY: 'Thailand',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.engineering.cloud.seek.com.au/a.png',
      },
    },
    [hk_staging_jobsdb_com.clientDomain]: {
      clientConfig: hk_staging_jobsdb_com,
      privacyChkBoxText: 'registration__privacy_consent__checkbox',
      environment: {
        SITE: 'JOBSDB_HK',
        COUNTRY: 'Hong Kong',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.engineering.cloud.seek.com.au/a.png',
      },
    },
    [my_staging_jobstreet_com.clientDomain]: {
      clientConfig: my_staging_jobstreet_com,
      privacyChkBoxText: 'registration__privacy_consent__checkbox',
      environment: {
        SITE: 'STAGING_JOBSTREET_MY',
        COUNTRY: 'Malaysia',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.engineering.cloud.seek.com.au/a.png',
      },
    },
    [sg_staging_jobstreet_com.clientDomain]: {
      clientConfig: sg_staging_jobstreet_com,
      privacyChkBoxText: 'registration__privacy_consent__checkbox',
      environment: {
        SITE: 'STAGING_JOBSTREET_SG',
        COUNTRY: 'Singapore',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.engineering.cloud.seek.com.au/a.png',
      },
    },
    [ph_staging_jobstreet_com.clientDomain]: {
      clientConfig: ph_staging_jobstreet_com,
      privacyChkBoxText: 'registration__privacy_consent__checkbox',
      environment: {
        SITE: 'STAGING_JOBSTREET_PH',
        COUNTRY: 'Philippines',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.engineering.cloud.seek.com.au/a.png',
      },
    },
    [id_staging_jobstreet_com.clientDomain]: {
      clientConfig: id_staging_jobstreet_com,
      privacyChkBoxText: 'registration__privacy_consent__checkbox',
      environment: {
        SITE: 'STAGING_JOBSTREET_ID',
        COUNTRY: 'Indonesia',
        IMPRESSION_TRACKING_ENABLED: true,
        IMPRESSION_TRACKING_URL:
          'https://tracking.engineering.cloud.seek.com.au/a.png',
      },
    },
  },
  hookDomain: 'candidate',
  themeName: 'seekJobs',
  originWhitelist: [
    'https://candidate-ui-staging.certsynonprod.com',
    'https://app.certsynonprod.com',
    'https://app.seekpass-staging.com',
  ],
} as RenderConfig;
